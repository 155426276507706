/* eslint-disable import/no-unresolved */
<template>
  <section id="users">
    <p>Gerenciamento de palestrantes</p>
    <div>
      <add-new-speaker
        :is-add-new-speaker-sidebar-active.sync="isAddNewSpeakerSidebarActive"
        @onSaved="onSaved($event)"
        @close="isAddNewSpeakerSidebarActive = false"
      />

      <edit-speaker
        :is-add-edit-speaker-sidebar-active.sync="isAddEditSpeakerSidebarActive"
        :data="rowData"
        @onSaved="onSaved($event)"
        @close="isAddEditSpeakerSidebarActive = false"
      />

      <detail-speaker
        :is-add-detail-speaker-sidebar-active.sync="isAddDetailSpeakerSidebarActive"
        :data="rowData"
        @close="isAddDetailSpeakerSidebarActive = false"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>palestrantes</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchValue"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                />
                <b-button
                  variant="success"
                  class="m-2"
                  @click="goToDirectInvite()"
                >
                  <span class="text-nowrap">Convite direto</span>
                </b-button>
                <b-button
                  variant="primary"
                  @click="isAddNewSpeakerSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar palestrantes</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="overflow-auto">
          <b-table
            id="mytable"
            ref="mytable"
            hover
            :items="items"
            :fields="tableColumnsCongress"
            :filter="searchValue"
            primary-key="id"
            :total-rows="rows"
            :per-page="perPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :current-page="currentPage"
            show-empty
            empty-text="Nenhuma informação encontrada"
            busy.sync="isBusy"
            empty-filtered-text="Nenhum registro filtrado"
          >
            <template slot="empty">
              <div
                v-if="isBusy"
                class="text-center"
              >
                <b-spinner class="align-middle" />
              </div>
              <div
                v-else
                class="text-center"
              >
                Nenhuma informação encontrada
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon
                    icon="EditIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="detail(data.item)">
                  <feather-icon
                    icon="EyeIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Detalhes</span>
                </b-dropdown-item>

                <b-dropdown-item @click="remove(data.item)">
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Deletar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }} palestrantes</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import AddNewSpeaker from './AddNewSpeaker.vue'
import EditSpeaker from './EditSpeaker.vue'
import DetailSpeaker from './DetailSpeaker.vue'
import SpeakerService from './speakerService'
import { mapActions } from 'vuex'

export default {

  components: {
    AddNewSpeaker,
    EditSpeaker,
    DetailSpeaker,
    // EditSpeaker,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewSpeakerSidebarActive = ref(false)
    const isAddEditSpeakerSidebarActive = ref(false)
    const isAddDetailSpeakerSidebarActive = ref(false)
    const isPassUserSidebarActive = ref(false)
    return {
      isAddNewSpeakerSidebarActive,
      isAddEditSpeakerSidebarActive,
      isAddDetailSpeakerSidebarActive,
      isPassUserSidebarActive
    }
  },
  data() {
    return {
      items: [],
      tableColumnsCongress: [
        {
          key: 'id',
          label: 'Código',
          sortable: true,
        },
        {
          key: 'user.name',
          label: 'Nome',
          sortable: true
        },
        {
          key: 'user.cpf',
          label: 'CPF',
          sortable: false,
          formatter(v) {
            if (v) {
              v = v.replace(/\D/g, '')
              v = v.replace(/(\d{3})(\d)/, '$1.$2')
              v = v.replace(/(\d{3})(\d)/, '$1.$2')
              v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

              return v
            }
            return true
          }
        },
        {
          key: 'actions',
          label: 'Ações',
          sortable: false,
        }
      ],
      rows: 0,
      perPage: 10,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prevPage: this.currentPage - 1,
      sortBy: 'id',
      sortDesc: true,
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      searchValue: '',
      isBusy: false,
      isRemoving: false,
      rowData: {}
    }
  },
  computed: {

  },
  watch: {

  },
  beforeMount() {
    this.listSpeakers()
  },
  methods: {
    goToDirectInvite() {
      const routerData = this.$router.resolve({ name: 'direct-invite' })
      window.open(routerData.href, '_blank')
    },
    onSaved(saved) {
      this.listSpeakers()
    },
    listSpeakers() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      this.items = []
      this.isBusy = true
      SpeakerService().fetchSpeaker(params).then(data => {
        data.response.speakers.forEach(speaker => {
          this.items.push(speaker)
        })
        this.rows = data.response.speakers.length
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
    },
    detail(data) {
      this.rowData.id = data.id
      this.rowData.photo = data.photo
      this.rowData.name = data.user.name
      this.rowData.name_badge = data.name_badge
      this.rowData.cpf = data.user.cpf
      this.rowData.is_brazilian = data.is_brazilian
      this.rowData.email = data.user.email
      this.rowData.phone = data.user.phone
      this.rowData.pronome = data.pronome
      this.rowData.lattes_link = data.lattes_link
      this.rowData.instagram = data.instagram
      this.rowData.mini_curriculo = data.mini_curriculo

      this.isAddDetailSpeakerSidebarActive = true
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.photo = data.photo
      this.rowData.name = data.user.name
      this.rowData.user_id = data.user.id
      this.rowData.name_badge = data.name_badge
      this.rowData.cpf = data.user.cpf
      this.rowData.is_brazilian = data.is_brazilian
      this.rowData.email = data.user.email
      this.rowData.phone = data.user.phone
      this.rowData.pronome = data.pronome
      this.rowData.lattes_link = data.lattes_link
      this.rowData.instagram = data.instagram
      this.rowData.mini_curriculo = data.mini_curriculo
      this.isAddEditSpeakerSidebarActive = true
    },
    remove(data) {
      this.$swal({
        title: 'Deletar palestrante?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => SpeakerService().deleteSpeaker(data.id).then(response => {
          if (response.status === 200) {
            const new_items = this.items.filter((v) => v.id !== data.id)
            this.items = new_items
          }
        }).catch(err => {
          this.$swal.fire(
            'Deletar!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Palestrante deletado com sucesso.',
            'success'
          )
        }
      })
    }
  },
}
</script>

<style lang="scss">
</style>
