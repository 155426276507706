<template>
  <b-sidebar
    id="add-new-speaker-sidebar"
    :visible="isAddNewSpeakerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-speaker-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar palestrante
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Imagem -->
          <validation-provider
            v-slot="{ errors }"
            name="image"
          >
            <b-form-group
              label="Foto do palestrante"
              label-for="image"
            >
              <b-form-file
                id="image"
                v-model="form.image"
                placeholder="Selecione a foto..."
                drop-placeholder="Arraste seu arquivo para aqui..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Nome Completo"
              label-for="name"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CPF -->
          <validation-provider
            v-slot="{ errors }"
            name="cpf"
            rules="required"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="cpf"
                v-model="form.cpf"
                v-mask="'###.###.###-##'"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                :autocomplete="'false'"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Nacionality -->
          <validation-provider
            v-slot="{ errors }"
            name="is_not_brazilian"
          >
            <b-form-group
              label="Não sou brasileiro"
              label-for="is_not_brazilian"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-checkbox
                id="is_not_brazilian"
                v-model="form.is_not_brazilian"
                name="is_not_brazilian"
                unchecked-value="not_accepted"
                :autocomplete="'false'"
                :state="errors.length > 0 ? false : null"
              >
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            v-slot="{ errors }"
            name="phone"
            rules="required"
          >
            <b-form-group
              label="Celular"
              label-for="phone"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                v-mask="'(##) #####-####'"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Name Badge -->
          <validation-provider
            v-slot="{ errors }"
            name="name_badge"
            rules="required"
          >
            <b-form-group
              label="Nome para crachá"
              label-for="name_badge"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name_badge"
                v-model="form.name_badge"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Pronome -->
          <validation-provider
            v-slot="{ errors }"
            name="pronome"
            rules="required"
          >
            <b-form-group
              label="Como gostaria de ser chamado?"
              label-for="pronome"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="pronome"
                v-model="form.pronome"
                :options="[{text:'Ele/Dele', value: 'Ele/Dele'}, {text:'Ela/Dela', value: 'Ela/Dela'}]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Lattes Link -->
          <validation-provider
            v-slot="{ errors }"
            name="lattes_link"
            rules="required"
          >
            <b-form-group
              label="Currículo Lattes"
              label-for="lattes_link"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="lattes_link"
                v-model="form.lattes_link"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Instagram -->
          <validation-provider
            v-slot="{ errors }"
            name="instagram"
          >
            <b-form-group
              label="Instagram"
              label-for="instagram"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="instagram"
                v-model="form.instagram"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Mini Currículo -->
          <validation-provider
            v-slot="{ errors }"
            name="mini_curriculo"
          >
            <b-form-group
              label="Mini Currículo"
              label-for="mini_curriculo"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="mini_curriculo"
                v-model="form.mini_curriculo"
                placeholder="Digite o miní currículo"
                rows="3"
                max-rows="6"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Adicionar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import speakerService from './speakerService'
import vSelect from 'vue-select'

extend('confirmed', {
  ...confirmed,
  message: 'Senha de confirmação diferente da senha.',
})

extend('min', {
  ...min,
  message: 'Mínimo de caracteres são 6.',
})

extend('required_if', {
  ...required_if,
  message: 'Esse campo é obrigatório.',
})

extend('integer', {
  ...integer,
  message: 'Esse aceita apenas números.',
})

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('email', {
  ...email,
  message: 'Digite um email válido.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewSpeakerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ptBR,
      form: {
        image: null,
        name: null,
        email: null,
        cpf: null,
        is_not_brazilian: 'not_accepted',
        phone: null,
        name_badge: null,
        pronome: null,
        lattes_link: null,
        instagram: null,
        mini_curriculo: null,
      },
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {
    resetForm() {
      this.form = {
        image: null,
        name: null,
        email: null,
        cpf: null,
        is_not_brazilian: 'not_accepted',
        phone: null,
        name_badge: null,
        pronome: null,
        lattes_link: null,
        instagram: null,
        mini_curriculo: null,
      }
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            this.isBusy = true
            const formData = new FormData()
            if (this.form.image != null) {
              const imagefile = document.querySelector('#image')
              formData.append('photo', imagefile.files[0])
            }
            formData.append('name', this.form.name)
            formData.append('cpf', this.form.cpf)
            formData.append('email', this.form.email)
            formData.append('phone', this.form.phone)
            formData.append('name_badge', this.form.name_badge)
            formData.append('is_not_brazilian', this.form.is_not_brazilian)
            formData.append('pronome', this.form.pronome)
            formData.append('lattes_link', this.form.lattes_link)
            formData.append('instagram', this.form.instagram)
            formData.append('mini_curriculo', this.form.mini_curriculo)
            speakerService().sendSpeaker(formData).then((response) => {
              console.log(response)
              if (response.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: response.message,
                    text: ` ERROR: ${response.errors[0][0]} #${response.errors[0][1]}`,
                    variant: 'danger',
                  }
                })
              } else if (response.status === 204) {
                this.$swal({
                  title: 'Atualizar usuário?',
                  text: response.message,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  cancelButtonText: 'Não',
                  confirmButtonText: 'Sim, desejo atualizar!',
                  showLoaderOnConfirm: true,
                  preConfirm: () => speakerService().updateEmailSpeaker(response.response.payload).then(res => {
                    if (res.status === 200) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: res.message,
                          icon: 'InfoIcon',
                          variant: 'success',
                        }
                      })
                      this.$emit('close')
                      this.$emit('onSaved', true)
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          icon: 'InfoIcon',
                          title: res.message,
                          variant: 'danger',
                        }
                      })
                    }
                  }).catch(err => {
                    this.$swal.fire(
                      'Não Atualizado!',
                      'Erro ao tentar atualizar.',
                      'error'
                    )
                  })
                    .finally(() => {
                      this.isBusy = false
                    }),
                  allowOutsideClick: () => !this.$swal.isLoading()
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$swal.fire(
                      'Atualizado!',
                      'Usuário atualizado com sucesso.',
                      'success'
                    )
                  }
                })
              } else if (response.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
              }
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: err.message,
                  variant: 'danger',
                }
              })
            })
              .finally(() => { this.isBusy = false })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-speaker-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
