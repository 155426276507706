/* eslint-disable no-restricted-globals */
<template>
  <b-sidebar
    id="add-detail-speaker-sidebar"
    :visible="isAddDetailSpeakerSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-detail-speaker-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Detalhes palestrante
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <div class="mb-3 d-flex justify-content-center">
            <b-avatar
              id="image_show_detail"
              :src="form.image_show"
              size="11rem"
              :button="avataIsButton"
              @click="showImage(form.image_show)"
            />
          </div>

          <!-- Name -->
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Nome Completo"
              label-for="name"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- CPF -->
          <validation-provider
            v-slot="{ errors }"
            name="cpf"
            rules="required"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="cpf"
                v-model="form.cpf"
                v-mask="'###.###.###-##'"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="email"
                v-model="form.email"
                readonly
                :autocomplete="'false'"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Nacionality -->
          <validation-provider
            v-slot="{ errors }"
            name="is_not_brazilian-detail"
          >
            <b-form-group
              label="Não sou brasileiro"
              label-for="is_not_brazilian-detail"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-checkbox
                id="is_not_brazilian-detail"
                v-model="form.is_not_brazilian"
                name="is_not_brazilian-detail"
                value="accepted"
                unchecked-value="not_accepted"
                :disabled="true"
                :autocomplete="'false'"
                :state="errors.length > 0 ? false : null"
              >
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            v-slot="{ errors }"
            name="phone"
            rules="required"
          >
            <b-form-group
              label="Celular"
              label-for="phone"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                v-mask="'(##) #####-####'"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Name Badge -->
          <validation-provider
            v-slot="{ errors }"
            name="name_badge"
            rules="required"
          >
            <b-form-group
              label="Nome para crachá"
              label-for="name_badge"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name_badge"
                v-model="form.name_badge"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Pronome -->
          <validation-provider
            v-slot="{ errors }"
            name="pronome"
            rules="required"
          >
            <b-form-group
              label="Como gostaria de ser chamado?"
              label-for="pronome"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="pronome"
                v-model="form.pronome"
                :disabled="true"
                :options="[{text:'Ele/Dele', value: 'Ele/Dele'}, {text:'Ela/Dela', value: 'Ela/Dela'}]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Lattes Link -->
          <validation-provider
            v-slot="{ errors }"
            name="lattes_link"
            rules="required"
          >
            <b-form-group
              label="Currículo Lattes"
              label-for="lattes_link"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="lattes_link"
                v-model="form.lattes_link"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Instagram -->
          <validation-provider
            v-slot="{ errors }"
            name="instagram"
          >
            <b-form-group
              label="Instagram"
              label-for="instagram"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="instagram"
                v-model="form.instagram"
                readonly
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Mini Currículo -->
          <validation-provider
            v-slot="{ errors }"
            name="mini_curriculo"
          >
            <b-form-group
              label="Mini Currículo"
              label-for="mini_curriculo"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="mini_curriculo"
                v-model="form.mini_curriculo"
                readonly
                placeholder="Digite o miní currículo"
                rows="3"
                max-rows="6"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import speakerService from './speakerService'
import vSelect from 'vue-select'

extend('confirmed', {
  ...confirmed,
  message: 'Senha de confirmação diferente da senha.',
})

extend('min', {
  ...min,
  message: 'Mínimo de caracteres são 6.',
})

extend('required_if', {
  ...required_if,
  message: 'Esse campo é obrigatório.',
})

extend('integer', {
  ...integer,
  message: 'Esse aceita apenas números.',
})

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('email', {
  ...email,
  message: 'Digite um email válido.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddDetailSpeakerSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isBusy: false,
      ptBR,
      avataIsButton: true,
      url_base: process.env.VUE_APP_API.replace('/api', ''),
      form: {
        id: null,
        user_id: null,
        image_show: null,
        image: null,
        name: null,
        email: null,
        cpf: null,
        is_not_brazilian: 'not_accepted',
        phone: null,
        name_badge: null,
        pronome: null,
        lattes_link: null,
        instagram: null,
        mini_curriculo: null,
      },
    }
  },
  computed: {
  },
  watch: {
  },
  updated() {
    this.avataIsButton = !!this.data.photo
    this.form.id = this.data.id
    this.form.user_id = this.data.user_id
    this.form.image_show = `${this.url_base}/storage/image/${this.data.photo}`
    this.form.name = this.data.name
    this.form.name_badge = this.data.name_badge
    this.form.cpf = this.data.cpf
    this.form.is_not_brazilian = this.data.is_brazilian !== 1 ? 'accepted' : 'not_accepted'
    this.form.email = this.data.email
    this.form.phone = this.data.phone
    this.form.pronome = this.data.pronome
    this.form.lattes_link = this.data.lattes_link
    this.form.instagram = this.data.instagram
    this.form.mini_curriculo = this.data.mini_curriculo
  },
  created() {
    this.avataIsButton = true
  },
  methods: {
    resetForm() {
      this.form = {
        id: null,
        user_id: null,
        image_show: null,
        image: null,
        name: null,
        email: null,
        cpf: null,
        is_not_brazilian: 'not_accepted',
        phone: null,
        name_badge: null,
        pronome: null,
        lattes_link: null,
        instagram: null,
        mini_curriculo: null,
      }
    },
    showImage(url) {
      const altura = 400
      const largura = 400
      // eslint-disable-next-line no-restricted-globals
      const esquerda = (screen.width - largura) / 2
      // eslint-disable-next-line no-restricted-globals
      const topo = (screen.height - altura) / 2
      window.open(url, '', `height=${altura}, width=${largura}, top=${topo}, left=${esquerda}`)
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            this.isBusy = true
            const formData = new FormData()
            if (this.form.image != null) {
              const imagefile = document.querySelector('#image')
              formData.append('photo', imagefile.files[0])
            }
            formData.append('user_id', this.form.user_id)
            formData.append('name', this.form.name)
            formData.append('cpf', this.form.cpf)
            formData.append('email', this.form.email)
            formData.append('phone', this.form.phone)
            formData.append('name_badge', this.form.name_badge)
            formData.append('is_not_brazilian', this.form.is_not_brazilian)
            formData.append('pronome', this.form.pronome)
            formData.append('lattes_link', this.form.lattes_link)
            formData.append('instagram', this.form.instagram)
            formData.append('mini_curriculo', this.form.mini_curriculo)
            speakerService().updateSpeaker(formData).then((response) => {
              if (response.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: response.message,
                    text: ` ERROR: ${response.errors[0][0]} #${response.errors[0][1]}`,
                    variant: 'danger',
                  }
                })
              } else if (response.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
              }
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: err.message,
                  variant: 'danger',
                }
              })
            })
              .finally(() => { this.isBusy = false })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-detail-speaker-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
